import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Loader, Message, Step } from 'semantic-ui-react';
import validator from 'validator';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import apiEndpoints from '../../commonjs/apiEndpoints';
import AdditionalDetails from '../../components/endorsements/AdditionalDetails';
import BasicDetails from '../../components/endorsements/BasicDetails';
import EndorsementList from '../../components/endorsements/EndorsementsList';
import SubtypeDetails from '../../components/endorsements/SubtypeDetails';
import { ENDORSEMENT_REQUEST_SENT_TO_INSURER } from '../../constants/endorsements';
import { default as PROVINCES } from '../../constants/provinces.json';
import { CONFIRMATION_MODAL, MODAL_TYPE_FILE_VIEWER, MODAL_TYPE_MESSAGE, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { THAI_REJECTION_REASONS } from '../../commonjs/commonData';
import {
  errorHandler,
  getCommissionFromTotalPremium,
  getDistrictOptions,
  getSubDistrictOptions,
  thaiToDefaultDate,
} from '../../utils/helper';

function EndorsementModal(props) {
  const languageMap = useSelector((state) => state.languageMap);
  const endorsementLangMap = languageMap.components.endorsement;
  const modalLangMap = endorsementLangMap.endorsementModal;

  const allowedSteps = ['select_flow', 'basic_details', 'additional_details'];
  const [fileList, setFileList] = useState({});
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(null);
  const [updateData, setUpdateData] = useState({});
  const [fileListLoading, setFileListLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState('basic_details');
  const [endorsements, setEndorsements] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [sub_districts, setSubDistricts] = useState([]);
  const [affiliate, setAffiliate] = useState(null);
  const blockDataUpdate =
    props.blockDataUpdate || data?.endorsement_status === ENDORSEMENT_REQUEST_SENT_TO_INSURER || data?.is_approved;

  let commissionRate = 0;

  // const languageMap = this.props.languageMap.components.remarksModal;
  function close() {
    props.hideModal(null, { ...props });
    if (props.postAction) {
      props.postAction();
    }
  }

  useEffect(() => {
    const data = { ...props.data };
    if (Object.keys(data).length) {
      if (!data.bank_detail) {
        data.bank_detail = {};
      }
      if (!data.rejection_reason) {
        data.rejection_reason = {};
      }
      setData(data);
      if (data.fairdeesale?.affiliate) {
        getAffiliate(data.fairdeesale?.affiliate);
      }
      if (data.documents && data.documents.length) {
        let files = {};
        data.documents.forEach((data) => {
          if (!files[data.document_type]) {
            files[data.document_type] = [];
          }
          data.file.name = data.file.original_file_name;
          files[data.document_type].push(data);
        });
        setFileList(files);
        return;
      }
    } else {
      if (props.sale) {
        getAffiliate({ id: props.sale.affiliate });
      }
      if (props.sale && props.sale.id && !props.endorsements) {
        getEndorsements();
      } else {
        emptyData();
      }
    }
  }, [props.data]);

  function getAffiliate(affiliate) {
    api.crm
      .affiliate(affiliate.id)
      .then((res) => {
        setAffiliate(res.message);
      })
      .catch((err) => errorHandler(err.response, true));
  }

  function getEndorsements(resetSteps = true) {
    setLoading(true);
    api.policy
      .getEndorsements(undefined, { fairdeesale: props.sale.id })
      .then((res) => {
        if (!res.results || !res.results.length) {
          emptyData();
          if (resetSteps) {
            setCurrentStep('basic_details');
          }
        } else {
          if (resetSteps) {
            setCurrentStep('select_flow');
          }
          setEndorsements(res.results);
        }
        setLoading(false);
      })
      .catch((err) => {
        emptyData();
      });
  }

  function updateCommission(premium = data.premium_after_tax, isCompulsory = data?.is_compulsory) {
    let quotation = data.fairdeesale ? data.fairdeesale.quotation : props.quotation;
    if (!premium) {
      return;
    }

    let originalPremium = 0;
    if (isCompulsory) {
      originalPremium = quotation.cmi_price_list?.premium_after_tax;
    } else {
      if (quotation.price_list?.voluntary_insurance_premium) {
        originalPremium = quotation.price_list.voluntary_insurance_premium;
      } else {
        originalPremium = quotation.price_list?.premium_after_tax;
      }
    }

    const updatedCommission = getCommissionFromTotalPremium(premium - originalPremium, quotation, isCompulsory);
    if (updatedCommission != data.commission_delta) {
      setData((prevState) => ({
        ...prevState,
        commission_delta: updatedCommission,
      }));

      setUpdateData((prevState) => ({
        ...prevState,
        commission_delta: updatedCommission,
      }));
    }
  }

  function emptyData() {
    setData({
      subtype_of_endorsement: {},
      rejection_reason: {},
      premium_after_tax: '',
      commission_delta: '',
      incentive_delta: '',
      bank_detail: {},
      refund_type: null,
    });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function confirmDelete(data) {
    props.showModal(CONFIRMATION_MODAL, {
      action: deleteDocumentApiCall,
      data,
    });
  }

  function deleteDocumentApiCall(data) {
    const messages = languageMap.messages;
    api.policy
      .deleteEndorsementDocument(data.id)
      .then((resp) => {
        fileList[data.document_type] = fileList[data.document_type].filter((d) => d.id !== data.id);
        setFileList({ ...fileList });
        notify(messages.deletedSuccessfully, 'success');
      })
      .catch((error) => {
        notify('Something went wrong', 'error');
      });
  }

  function approveEndorsement(endorsement) {
    const messages = languageMap.messages;
    props.showModal(CONFIRMATION_MODAL, {
      text: 'Are you sure want to approve this endorsement?',
      infoText: 'This action will create invoices against the endorsement.',
      action: () => {
        api.policy
          .patchEndorsements(data.id, {
            is_approved: true,
          })
          .then((res) => {
            notify(messages.updatedSuccessfully, 'success');
            if (res.has_line_users === false) {
              openMsgModal();
            }
            close();
          })
          .catch((err) => {
            errorHandler(err.response, true);
          });
      },
    });
  }

  function fileUploaded(file, name) {
    let promises = [];
    fileListLoading[name] = true;
    setFileListLoading(fileListLoading);

    if (Array.isArray(file)) {
      file.forEach((f) => {
        let params = new FormData();
        params.append('endorsement_id', parseInt(data.id, 10));
        params.append('document_type', name);
        params.append('file', f);
        promises.push(uploadFileApiCall(params));
      });
    } else {
      let params = new FormData();
      params.append('endorsement_id', parseInt(data.id, 10));
      params.append('document_type', name);
      params.append('file', file);
      promises.push(uploadFileApiCall(params));
    }

    Promise.all(promises)
      .then((response) => {
        fileListLoading[name] = false;
        response.forEach((resp, index) => {
          if (Array.isArray(file)) {
            file[index].id = resp.message.id;
            file[index].document_type = name;
          } else {
            file.id = resp.message.id;
            file.document_type = name;
          }

          if (!fileList[name]) {
            fileList[name] = [file[index] || file];
          } else {
            fileList[name].push(file[index] || file);
          }
        });
        setFileListLoading({ ...fileListLoading });
        setFileList({ ...fileList });
        props.loadCovidForm();
      })
      .catch((error) => {
        setLoading(true);
      });
  }

  function deleteDocument(data) {
    confirmDelete(data);
  }

  function validate() {
    let messages = languageMap.messages,
      errors = {};
    if (currentStep === 'basic_details') {
      if (!data.type_of_endorsement && !updateData.type_of_endorsement) {
        errors.type_of_endorsement = messages.required;
      }
      if (data.rejection_reason) {
        let errors_rejection_reason = {};
        if (!data.rejection_reason.rejection_reason) {
          errors_rejection_reason.rejection_reason = messages.required;
        }
        if (!data.rejection_reason.additional_rejection_reason) {
          errors_rejection_reason.additional_rejection_reason = messages.required;
        }
        if (Object.keys(errors_rejection_reason).length) {
          errors.rejection_reason = errors_rejection_reason;
        }
      }

      if (['premium_addition', 'premium_reduction'].includes(updateData.type_of_endorsement)) {
        if (!updateData.premium_after_tax) {
          errors.premium_after_tax = messages.required;
        }
        if (parseFloat(updateData.premium_after_tax) === 0) {
          errors.premium_after_tax = `${messages.moreValue} 0`;
        }
      }
    }
    if (currentStep === 'additional_details') {
      if (data.type_of_endorsement !== 'premium_unaffected') {
        if (updateData.bank_detail) {
          const bank_detail = updateData.bank_detail;
          if (!bank_detail.ac_number) {
            errors.ac_number = messages.required;
          }
          if (!bank_detail.ac_holder_name) {
            errors.ac_holder_name = messages.required;
          }
          if (!bank_detail.bank_name) {
            errors.bank_name = messages.required;
          }
          if (!bank_detail.branch_code) {
            errors.branch_code = messages.required;
          }
          if (bank_detail.branch_code) {
            if (bank_detail.branch_code.length > 4) {
              errors.branch_code = messages['Branch Code can only have 4 characters'];
            }
          }
        } else {
          if (!data.bank_detail || (data.bank_detail && !Object.keys(data.bank_detail).length)) {
            errors.ac_holder_name = messages.required;
            errors.ac_number = messages.required;
            errors.bank_name = messages.required;
            errors.branch_code = messages.required;
          } else {
            const bank_detail = data.bank_detail;
            if (!bank_detail.ac_number) {
              errors.ac_number = messages.required;
            }
            if (!bank_detail.ac_holder_name) {
              errors.ac_holder_name = messages.required;
            }
            if (!bank_detail.bank_name) {
              errors.bank_name = messages.required;
            }
            if (!bank_detail.branch_code) {
              errors.branch_code = messages.required;
            }
          }
        }
      }

      if (!data.settle_with_affiliate) {
        if (!fileList.policy || !fileList.policy.length) {
          errors.policy = messages.required;
        }
        if (!fileList.customer_id || !fileList.customer_id.length) {
          errors.customer_id = messages.required;
        }
      }

      if (data.is_short_rate_applicable === null && updateData.is_short_rate_applicable === null) {
        errors.is_short_rate_applicable = messages.required;
      }
    }

    setErrors(errors);
    return errors;
  }

  function uploadFileApiCall(params) {
    return api.policy.postEndorsementDocument(params);
  }

  function next() {
    if (currentStep === 'select_flow') {
      changeStep('basic_details');
    } else if (currentStep === 'basic_details') {
      if (data.subtype_of_endorsement) {
        return changeStep('subtype_details');
      }
      changeStep('additional_details');
    } else if (currentStep === 'subtype_details') {
      changeStep('additional_details');
    } else {
      close();
    }
  }

  async function handleProvinceChange(e, dropdownData) {
    let insured_address = {
        ...(data.subtype_of_endorsement.insured_address || {}),
        district: null,
        subdistrict: null,
        province: dropdownData.value,
      },
      provinceCode = PROVINCES.find((province) => province.provincename === dropdownData.value).provincecode,
      districts = await getDistrictOptions(provinceCode);

    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));
    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));
    setDistricts(districts);
    setSubDistricts([]);
  }

  function handleRegistrationProvinceChange(e, dropdownData) {
    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, registration_province: dropdownData.value },
    }));

    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, registration_province: dropdownData.value },
    }));
  }

  async function handleDistrictChange(e, dropdownData) {
    let insured_address = {
        ...(data.subtype_of_endorsement.insured_address || {}),
        district: dropdownData.value,
        subdistrict: null,
      },
      districtCode = districts.find((district) => district.districtname === dropdownData.value).districtcode,
      subDistricts = await getSubDistrictOptions(districtCode);

    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));
    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));

    setSubDistricts(subDistricts);
  }

  function handleSubDistrictChange(e, dropdownData) {
    let insured_address = {
      ...(data.subtype_of_endorsement.insured_address || {}),
      subdistrict: dropdownData.value,
      postal_code: sub_districts.find((subDistrict) => subDistrict.subdistrictname === dropdownData.value).zip,
    };

    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));
    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_address },
    }));
  }

  function onDobChange(date) {
    date = thaiToDefaultDate(date);
    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_dob: date },
    }));
    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, insured_dob: date },
    }));
  }

  function handleAddressChange(e, name) {
    const value = e.target.value;

    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: {
        ...prevState.subtype_of_endorsement,
        insured_address: { ...data.subtype_of_endorsement.insured_address, [name]: value },
      },
    }));

    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: {
        ...prevState.subtype_of_endorsement,
        insured_address: { ...data.subtype_of_endorsement.insured_address, [name]: value },
      },
    }));
  }

  function handleSubtypeChange(name, event) {
    let value = event.target.value;

    if (name === 'chassis_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'chassis_number') {
      value = value ? value.toUpperCase() : value;
    }
    if (name === 'engine_number' && !validator.isAlphanumeric(value) && value !== '') {
      return;
    }
    if (name === 'engine_number') {
      value = value ? value.toUpperCase() : value;
    }

    setData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, [name]: value },
    }));

    setUpdateData((prevState) => ({
      ...prevState,
      subtype_of_endorsement: { ...prevState.subtype_of_endorsement, [name]: value },
    }));
  }

  function continueAction() {
    let promise = null;
    if (!data) {
      next();
    } else if (!data.id) {
      promise = api.policy.postEndorsements({ ...updateData, fairdeesale: props.sale.id });
    } else {
      let params = {
        ...updateData,
      };
      if (
        currentStep === 'additional_details' &&
        !Boolean(data.endorsement_status === 'endorsement_request_sent_to_insurer')
      ) {
        if (data.type_of_endorsement === 'policy_cancelled') {
          params.trigger_insurer_email = true;
        }
        params.endorsement_status = 'endorsement_request_sent_to_insurer';
      }
      promise = api.policy.patchEndorsements(data.id, params);
    }
    promise
      .then((res) => {
        if (!res.bank_detail) {
          res.bank_detail = {};
        }
        setData(res);
        next();
        setUpdateData({});
        props.onUpdateAction && props.onUpdateAction(res);
        getEndorsements(false);
      })
      .catch((err) => {
        errorHandler(err.response, true);
      });
  }

  function submit() {
    const errors = validate();
    if (Object.keys(errors).length) {
      return;
    }

    if (!Object.keys(updateData).length) {
      next();
      return;
    }
    if (updateData?.rejection_reason?.rejection_reason in THAI_REJECTION_REASONS) {
      updateData.rejection_reason.rejection_reason =
        THAI_REJECTION_REASONS[updateData.rejection_reason.rejection_reason];
    }
    props.showModal(CONFIRMATION_MODAL, {
      action: continueAction,
      text: `Do you want to ${data.id ? 'Update Endorsement Details' : 'Request for Endorsement'}?`,
    });
  }

  function handleChange(name, event) {
    let value = event.target.value;
    const numberInputs = ['commission_delta', 'premium_after_tax', 'incentive_delta'],
      bankDetailInputs = ['ac_number', 'ac_holder_name', 'branch_code', 'bank_name'],
      rejectionReasonInputs = ['additional_rejection_reason'];
    let updatedCommission = 0;
    if (numberInputs.includes(name) && isNaN(value)) {
      return;
    }

    if (bankDetailInputs.includes(name)) {
      value = {
        ...data.bank_detail,
        [name]: value,
      };
      name = 'bank_detail';
    }

    if (rejectionReasonInputs.includes(name)) {
      value = {
        ...data.rejection_reason,
        [name]: value,
      };
      name = 'rejection_reason';
    }

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name == 'premium_after_tax') {
      updateCommission(value);
    }
  }

  function onRadioChange(e, { value }, key) {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
      subtype_of_endorsement: {},
    }));
    setUpdateData((prevState) => ({
      ...prevState,
      [key]: value,
      subtype_of_endorsement: {},
    }));
  }

  function onCheckboxChange(e, checkData, key) {
    if (['is_compulsory', 'is_short_rate_applicable'].includes(key)) {
      setData((prevState) => ({
        ...prevState,
        [key]: checkData.checked,
      }));

      setUpdateData((prevState) => ({
        ...prevState,
        [key]: checkData.checked,
      }));

      if (key === 'is_compulsory') {
        updateCommission(data.premium_after_tax, checkData.checked);
      }
    } else if (key === 'settle_with_affiliate') {
      let bankDetails = {};
      if (checkData.checked) {
        if (affiliate.bank_details && affiliate.bank_details.length) {
          const bank_details = affiliate.bank_details[0];
          bankDetails['ac_number'] = bank_details['ac_number'];
          bankDetails['ac_holder_name'] = bank_details['ac_holder_name'];
          bankDetails['branch_code'] = bank_details['branch_code'];
          bankDetails['bank_name'] = bank_details['bank_name'];
        }
      }
      setData((prevState) => ({
        ...prevState,
        settle_with_affiliate: checkData.checked,
        bank_detail: bankDetails,
      }));

      setUpdateData((prevState) => ({
        ...prevState,
        settle_with_affiliate: checkData.checked,
        bank_detail: bankDetails,
      }));
    } else {
      const tempMap = data[key];
      if (checkData.checked) {
        tempMap[checkData.name] = '';
      } else {
        delete tempMap[checkData.name];
      }

      setData((prevState) => ({
        ...prevState,
        [key]: tempMap,
      }));

      setUpdateData((prevState) => ({
        ...prevState,
        [key]: tempMap,
      }));
    }
  }

  function onDropdownChange(e, { value }, key) {
    const rejectionReasonKeys = ['rejection_reason', 'insurer_rejection_reason'],
      bankDetailKeys = ['bank_name'];

    if (bankDetailKeys.includes(key)) {
      value = {
        ...data.bank_detail,
        [key]: value,
      };
      key = 'bank_detail';
    }

    if (rejectionReasonKeys.includes(key)) {
      value = {
        ...data.rejection_reason,
        [key]: value,
      };
      key = 'rejection_reason';
      // if not insurer rejected delete any insurer rejection reason if stored in insurer rejection reasons.
      if (
        value.rejection_reason !==
        languageMap.components.fairdeeWizard.docsRejection.docsRejectionReason.insurerRejected
      ) {
        delete value.insurer_rejection_reason;
      }
    }

    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setUpdateData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }

  function onButtonClick(e, endorsement) {
    e.stopPropagation();
    setData(endorsement);
  }

  function onRecordNewClick() {
    emptyData();
    next();
  }

  function openFileViewer(data, is_credit) {
    props.showModal(MODAL_TYPE_FILE_VIEWER, {
      data,
      action: fileViewerAction,
      invoice_id: data.invoice_id,
      quotation_id: data.id,
      finalAmount: data.premium_after_tax,
      downloadPath: apiEndpoints.endorsementDocument,
      is_corona: true,
    });
  }
  function fileViewerAction() {}

  function deleteEndorsement(e, endorsement) {
    e.stopPropagation();
    const messages = languageMap.messages;
    props.showModal(CONFIRMATION_MODAL, {
      action: () => {
        api.policy
          .deleteEndorsement(endorsement.id)
          .then((res) => {
            notify(messages.deletedSuccessfully, 'success');
            getEndorsements();
          })
          .catch((err) => {
            errorHandler(err.response);
          });
      },
    });
  }

  function renderEndorsements() {
    return (
      <div className="center-aligned">
        <Message
          size="tiny"
          color="yellow"
          content={
            <>
              <div>
                <button className="button primary medium" onClick={onRecordNewClick}>
                  {modalLangMap.recordEndorsement}
                </button>
              </div>
              <p>{modalLangMap.or}</p>
              <p>{modalLangMap.selectedEndorsementContinue}</p>
            </>
          }
        />
        <EndorsementList
          endorsements={endorsements}
          onButtonClick={onButtonClick}
          buttonText={modalLangMap.select}
          selectedEndorsement={data || {}}
          deleteEndorsement={deleteEndorsement}
        />
      </div>
    );
  }

  function changeStep(step) {
    setCurrentStep(step);
  }

  function openMsgModal() {
    props.showModal(MODAL_TYPE_MESSAGE, {
      children: (
        <div>{languageMap.components.fairdeeWizard.docsRejectionModal['Line message cant be sent to the agent']}</div>
      ),
      hideCancel: true,
    });
  }

  if (!data && !endorsements.length) {
    return <div></div>;
  }

  return (
    <div className="fairdee-modal-wrapper customer-info large">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text black">{modalLangMap.endorsementRequest}</span>
          </p>
        </div>
        <h1 className="title-text black">{modalLangMap.endorsementRequest}</h1>
        <div className="stepper">
          <Step.Group size="mini" unstackable attached="top" widths={endorsements && endorsements.length > 0 ? 4 : 3}>
            {endorsements && endorsements.length > 0 && (
              <Step onClick={() => changeStep('select_flow')} active={currentStep === 'select_flow'}>
                <Step.Content>
                  <Step.Title>{modalLangMap.selectEndorsement}</Step.Title>
                </Step.Content>
              </Step>
            )}
            <Step onClick={() => changeStep('basic_details')} active={currentStep === 'basic_details'} disabled={!data}>
              <Step.Content>
                <Step.Title>{modalLangMap.basicDetails}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              onClick={() => changeStep('subtype_details')}
              active={currentStep === 'subtype_details'}
              disabled={!data || !data.subtype_of_endorsement}
            >
              <Step.Content>
                <Step.Title>{modalLangMap.additionalDetails}</Step.Title>
              </Step.Content>
            </Step>
            <Step
              onClick={() => changeStep('additional_details')}
              active={currentStep === 'additional_details'}
              disabled={!data || !data.id}
            >
              <Step.Content>
                <Step.Title>{modalLangMap.attachmentStatuses}</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
        {loading && <Loader size="huge">{modalLangMap.loading}</Loader>}
        {currentStep === 'select_flow' && renderEndorsements()}
        {currentStep === 'basic_details' && (
          <BasicDetails
            onRadioChange={onRadioChange}
            onCheckboxChange={onCheckboxChange}
            handleChange={handleChange}
            onDropdownChange={onDropdownChange}
            data={data}
            errors={errors}
            blockDataUpdate={blockDataUpdate}
          />
        )}
        {currentStep === 'subtype_details' && (
          <SubtypeDetails
            handleChange={handleSubtypeChange}
            handleAddressChange={handleAddressChange}
            handleDistrictChange={handleDistrictChange}
            handleSubDistrictChange={handleSubDistrictChange}
            handleProvinceChange={handleProvinceChange}
            handleRegistrationProvinceChange={handleRegistrationProvinceChange}
            onDobChange={onDobChange}
            districts={districts}
            sub_districts={sub_districts}
            errors={errors}
            subTypes={data.subtype_of_endorsement || {}}
            blockDataUpdate={blockDataUpdate}
          />
        )}

        {currentStep === 'additional_details' && (
          <AdditionalDetails
            fileList={fileList}
            fileListLoading={fileListLoading}
            fileUploaded={fileUploaded}
            deleteDocument={deleteDocument}
            blockPaymentUpdate={props.blockPaymentUpdate}
            blockUpdate={props.blockUpdate}
            errors={errors}
            data={data}
            onDropdownChange={onDropdownChange}
            approveEndorsement={approveEndorsement}
            affiliate={affiliate}
            handleChange={handleChange}
            onCheckboxChange={onCheckboxChange}
            blockDataUpdate={blockDataUpdate}
            onRadioChange={onRadioChange}
          />
        )}

        <div className="buttons-wrap">
          <button className="button medium orange-bordered-button" onClick={close.bind(null, true)}>
            {modalLangMap.cancel}
          </button>
          {!props.blockUpdate ? (
            currentStep === 'select_flow' && (!data || !data.id) ? (
              <button className="button medium disabled" onClick={submit} disabled>
                {modalLangMap.continue}
              </button>
            ) : (
              <button className="button medium" onClick={submit}>
                {modalLangMap.continue}
              </button>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default connect(null, { showModal, hideModal, showNotification })(EndorsementModal);
