import { Button } from 'antd';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
// import { useAppSelector } from '../../hooks/hooks';
import errorIcon from '../../images/fairdee-x-plus/icons/incomplete.svg';
import Loader from '../utils/Loader';
import './_pdfviewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  url: string;
  pagination_type?: 'bullet' | 'button';
  showPagination?: boolean;
  tools?: boolean;
  downloadable?: boolean;
  fullScreen?: boolean;
  zoomInTreshold?: any;
  toolsAtBottom?: boolean;
  pageScaleFactor?: any;
  width?: number;
}

const PdfViewer: React.FC<Props> = ({
  url,
  pagination_type = 'bullet',
  tools = false,
  downloadable,
  showPagination,
  fullScreen,
  zoomInTreshold = 1,
  toolsAtBottom = false,
  pageScaleFactor = 0.1,
  width = 1200,
}) => {
  const [numPages, setNumPages] = useState(0),
    [pageNumber, setPageNumber] = useState(1),
    [pageScale, setPageScale] = useState(fullScreen ? 1 : 0.6),
    zoomOutTreshold = 0.6,
    previewUrl = `${url}${downloadable ? '&inline=true' : ''}`,
    languageMap = useSelector((state: any) => state.languageMap.components.aboutUs);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number, type: string) {
    if (type === 'bullet') {
      setPageNumber(offset);
    } else {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  }

  function previousPage() {
    changePage(-1, 'button');
  }

  function nextPage() {
    changePage(1, 'button');
  }

  function handleZoomIn() {
    if (pageScale < zoomInTreshold) {
      setPageScale(pageScale + pageScaleFactor);
    }
  }

  function handleZoomOut() {
    if (pageScale > zoomOutTreshold) {
      setPageScale(pageScale - pageScaleFactor);
    }
  }

  function pagination() {
    let pageIndicator: any = [];
    if (numPages > 1) {
      for (let i = 1; i < numPages + 1; i++) {
        pageIndicator.push(
          <div
            className={`indicator ${pageNumber === i ? 'active' : ''}`}
            onClick={() => {
              changePage(i, 'bullet');
            }}
          ></div>
        );
      }
    }
    return pageIndicator;
  }

  function loader() {
    return (
      <section className="loader">
        <Loader titleText={languageMap['Loading']} secondaryText={languageMap['Please wait']} />
      </section>
    );
  }

  function loadError() {
    return (
      <section className="error-load">
        <div className="icon-wrapper">
          <img src={errorIcon} className="icon" />
        </div>
        <p className="text">{languageMap['Failed to load PDF']}</p>
      </section>
    );
  }

  function renderTools() {
    if (!tools) {
      return;
    }
    let containerClass = 'zoom-action',
      buttonClass = 'buttons';

    if (toolsAtBottom) {
      buttonClass = `${buttonClass} mr-4`;
      containerClass = `${containerClass} align-center mt-6`;
    } else {
      buttonClass = '${buttonClass} orange-buttons';
    }

    return (
      <div className={containerClass}>
        <Button className={buttonClass} onClick={handleZoomIn} disabled={pageScale >= zoomInTreshold}>
          {/* <Icon icon="akar-icons:zoom-in" /> */}
        </Button>
        <Button className={buttonClass} onClick={handleZoomOut} disabled={pageScale <= zoomOutTreshold}>
          {/* <Icon icon="akar-icons:zoom-out" /> */}
        </Button>
      </div>
    );
  }

  return (
    <section className="pdf-viewer">
      <div className={fullScreen ? 'pdf-content full-screen' : 'pdf-content scaled-down'}>
        <div className="pdf-doc">
          <>{!toolsAtBottom && renderTools()}</>
          <Document file={`${previewUrl}`} onLoadSuccess={onDocumentLoadSuccess} error={loadError} loading={loader}>
            <Page
              pageNumber={pageNumber}
              scale={pageScale}
              width={width}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </div>
      </div>
      <>{toolsAtBottom && renderTools()}</>
      {showPagination && (
        <div className="pagination">
          {pagination_type === 'bullet' ? (
            <div className="bullet mt-12">{pagination()}</div>
          ) : (
            <>
              <div className="page-counter">
                {languageMap['Page']} {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </div>
              <div className="button-counter">
                <Button className="orange-button buttons" disabled={pageNumber <= 1} onClick={previousPage}>
                  {languageMap['Prev']}
                </Button>
                <Button className="orange-button buttons" disabled={pageNumber >= numPages} onClick={nextPage}>
                  {languageMap['Next']}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default PdfViewer;
