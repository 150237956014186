import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal';
import { showNotification } from '../../actions/notification';
import api from '../../commonjs/api';
import { CONFIRMATION_MODAL, NOTIFICATION } from '../../constants/types';
import crossLg from '../../images/cross-blue.svg';
import cross from '../../images/cross.svg';
import { errorHandler } from '../../utils/helper';

function UpdateInsurerCreditsModal(props) {
  const [amount, setAmount] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const messages = useSelector((state) => state.languageMap.messages);

  function close() {
    props.hideModal(null, { ...props });
  }

  function notify(message, type) {
    props.showNotification(NOTIFICATION, {
      message: message,
      type: type,
      autoClose: true,
    });
  }

  function validate(updatedAmount) {
    let validationErrors = {};

    if ((updatedAmount && isNaN(updatedAmount)) || isNaN(amount)) {
      validationErrors.amount = messages.invalidFormat;
    }

    setErrors(validationErrors);
    return validationErrors;
  }

  function handleChange(event) {
    let value = event.target.value;
    let validationErrors = validate(value);
    if (!Object.keys(validationErrors).length) {
      setAmount(value);
    }
  }

  function addInsurerCreditTransaction() {
    const errors = validate();
    if (Object.keys(errors).length) {
      return;
    }
    setApiLoading(true);
    api.policy
      .postInsurerCreditTxn({
        insurer_credit: props.credit.id,
        amount: Number(amount),
      })
      .then((res) => {
        showNotification('Credit Updated Successfully', 'success');
        setApiLoading(false);
        props.action && props.action();
        close();
      })
      .catch((err) => {
        setApiLoading(false);
        const message = err && err.response && err.response.data && err.response.data.length && err.response.data[0];
        if (message) {
          notify(message, 'error');
        } else {
          errorHandler(err.response);
        }
      });
  }

  function openConfirmationModal() {
    props.showModal(CONFIRMATION_MODAL, {
      action: addInsurerCreditTransaction,
      text: 'Are you sure to continue?',
    });
  }

  return (
    <div className="fairdee-modal-wrapper medium">
      <div className="modal-content">
        <img src={cross} className="close" alt="" onClick={close} />
        <div className="mobile-header">
          <p className="current-page" onClick={close}>
            <img src={crossLg} alt="" />
            <span className="title-text">Update {props.credit.insurer.name} Credits</span>
          </p>
        </div>
        <h1 className="title-text">Update {props.credit.insurer.name} Credits</h1>
        <div className="options-container">
          <div className="form-wrapper">
            <TextField
              id="filled-error"
              label="Amount"
              error={Boolean(errors.amount)}
              helperText={errors.amount}
              onChange={handleChange}
              value={amount}
            />
          </div>
        </div>
        <div className="options-container">
          <div className="row">
            <br />
            <br />
            <button className="button medium orange-bordered-button" onClick={close}>
              Cancel Verification
            </button>
            <button className="button medium" onClick={openConfirmationModal} disabled={apiLoading}>
              {apiLoading && <CircularProgress size={14} color="inherit" />}
              {!apiLoading && <>Click to verify statement</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { hideModal, showNotification, showModal })(UpdateInsurerCreditsModal);
